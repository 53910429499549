// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD3JKiWzjQUfg2KA-nexEmeiBili7zdZUc",
  authDomain: "spring-simplified-system.firebaseapp.com",
  projectId: "spring-simplified-system",
  storageBucket: "spring-simplified-system.appspot.com",
  messagingSenderId: "861627842311",
  appId: "1:861627842311:web:95e04a617b13b8e9994fa2",
  measurementId: "G-4ZB8RJF6Z2"
};
const firebaseConfigProd = {
  apiKey: "AIzaSyAD47YtX_dK1JwpR2gxuU1xrBDKG36xLhI",
  authDomain: "spring-simplified-system-prod.firebaseapp.com",
  projectId: "spring-simplified-system-prod",
  storageBucket: "spring-simplified-system-prod.firebasestorage.app",
  messagingSenderId: "402462584298",
  appId: "1:402462584298:web:4fbeae664ffb63daf25693",
  measurementId: "G-7G5XQBVF3C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfigProd);
export const auth = getAuth(app);
export default app;